<template>
  <div class="v_change_bank">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>银行卡</span>
      </template>
    </head-back>

    <div class="v-change-info-content">
      <div class="v-change-info-form">
        <div class="v-change-info-form-item">
           <van-cell title="选择银行:" class="v-change-info-form-item-date-cell" is-link  :value="form.bank_name ? form.bank_name: '请选择银行'" @click="bankClick"/>
        </div>
        <div class="v-change-info-form-item">
           <van-field :readonly="$global.userInfo.name ? true: false" v-model="form.name" label="开户姓名:" placeholder="请输入姓名" clearable/>
        </div>
        <div class="v-change-info-form-item">
           <van-field v-model="form.card_number" label="银行卡号:" placeholder="请输入银行卡号" clearable/>
        </div>
      </div>
    </div>

    <change-bank ref="ChangeBank" @emitBankPicker="emitBankPicker"/>

    <div class="v-change-info-btn">
      <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import ChangeBank from '../../components/ChangeBank.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import { apiBindBnk, apiGetBankList, apiGetUserInfo, apiChangeBank } from '@/utils/api.js'
  export default {
    components: { HeadBack, ChangeBank, OperationButton },
    data() {
      return {
        value: '',
        form: {
          type: 1,
          bank_name: '',
          name: '',
          card_number: ''
        },
        readOnly: true,

        buttonObj: {
          claSS: 'c-btn-default',
          title: '确认提交',
          event: 'submitBankClick'
        },

        bankList: []
      }
    },
    created() {
      if(this.$global.userInfo != {}) {
        if(this.$global.userInfo.bank) {
          let copyObj = JSON.parse(JSON.stringify(this.$global.userInfo.bank))
          this.form = copyObj
        }
        this.form.name = this.$global.userInfo.name
      }
      this.apiGetBankListHandel()
    },
    methods: {

      // 获取银行卡列表
      async apiGetBankListHandel() {
        const { success, data } = await apiGetBankList({ type: 'ALL' })
        if(!success) return
        this.bankList = data.list
        console.log(data)
      },

      submitBankClick() {
        if(this.$global.userInfo != {}) {
          if(!this.$global.userInfo.bank) return this.apiBindBnkHandel()
          return this.apiChangeBankHandel()
        }
      },
      
      // 绑定银行卡
      async apiBindBnkHandel() {
        this.$global.loadingShow = true
        this.form.card_number = this.form.card_number.replace(/\s*/g,"")
        const { success, data } = await apiBindBnk(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
      },

      // 修改银行卡
      async apiChangeBankHandel() {
        this.$global.loadingShow = true
        this.form.card_number = this.form.card_number.replace(/\s*/g,"")
        const { success, data } = await apiChangeBank(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
      },

      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.$global.setUserInfo(data.data)
      },

      bankClick() {
        this.$refs.ChangeBank.onShow(true, this.bankList, this.form.bank_name)
      },

      emitBankPicker(val) {
        this.form.bank_name = val.bank_name
      },
      
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },
    }
  }
</script>

<style lang="scss">
.v_change_bank {
  height: 100%;
  background: #F1F1F1;
  .v-change-info-content {
    padding: 10px;
    .v-change-info-form {
      background: $white;
      border-radius: 8px;
      overflow: hidden;
      .v-change-info-form-item {
        .v-change-info-form-item-box {
          padding: 10px 16px;
          .v-change-info-form-item-label {
            flex: 1;
            color: #646566;
            text-align: left;
            font-size: 14px;
          }
          .van-radio-group {
            flex: 2;
            font-size: 14px;
          }
        }

        .van-cell {
          .van-cell__title  {
            margin-right: 0;
            flex: 1;
            color: #646566;
          }
          .van-cell__value {
            flex: 2;
            text-align: left;
            .van-field__control {
              color: #969799;
            }
          }
        }

        .v-change-info-form-item-date-cell {
          .van-cell__value {
            padding-left: 10px;
          }
        }
      }
    }
  }
  .v-change-info-btn {
    margin-top: 20px;
  }
}
</style>